import abi from "./contract/GlobalPool.json";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import Buy from "./component/buy"



import "./copy.png";
import Buy3 from "./component/buy3";




function App() {
  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[directs,setDirects]=useState("None");
  const[level,setLevel]=useState("None");
  const[wlevel,setLevelw]=useState("None");
  const[plevel,setLevelp]=useState("None");
  const[pack,setpackage]=useState("None");
  const[pack3,setpackage3]=useState("None");
  const[pack1,setpackage1]=useState("None");
  const[refer,setrefer]=useState("None");
const [tasks,setTasks] = useState([]);
const [tasks1,setTasks1] = useState([]);
const [tt,settt]=useState("None");
const [tt4,settt4]=useState("None");
const[dd,setDD]=useState("None");
const[dd1,setDD1]=useState("None");
const[dd2,setDD2]=useState("None");
const[referlink,setreflink]=useState("None");
const[bonanza,setBonanza]=useState("None");
const[upd,setupd]=useState("None");
const [tt1,settt1]=useState("None");
const [roi,seroi]=useState("None");
const [roi1,seroi1]=useState("None");
const [roi2,seroi2]=useState("None");
const [a1,sea1]=useState("None");
const [a2,sea2]=useState("None");
const [a3,sea3]=useState("None");
const [tt2,settt2]=useState("None");
const [tt3,settt3]=useState("None");

  useEffect(() => {
    
    const connectWallet = async () => {
      
      const contractAddress = "0x8d09a2e7a26e96d91a178bd99ac5e9fdc6d87983";
      const contractABI = abi.abi;
     
      const searchParams = new URLSearchParams(window.location.search);
    const myParam = searchParams.get('ref');
    console.log(myParam);
    document.querySelector("#name").value=myParam;
if(myParam == null)
  {
    document.querySelector("#name").value="0x98fd79B322029e370e15d65Ae2f4b4eAc733556B";
  }
  
  try {
    const { ethereum } = window;
  //  document.querySelector("#test").value = "0";
    if (ethereum) {
      const account = await ethereum.request({
        method: "eth_requestAccounts",
       
      });
     
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });

      window.ethereum.on("accountsChanged", () => {
        
        window.location.reload();
      });
          const provider = new ethers.providers.Web3Provider(ethereum);
        
          try {
            const selectedNetwork = await provider.getNetwork();
          
            if (selectedNetwork.chainId !== 56) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet chainId is 97 in decimal, which is 0x61 in hex
              });
              console.log("You have switched to the Binance network");
            } else {
              console.log("You are already on the Binance network");
            }
          } catch (switchError) {
            // The network has not been added to MetaMask
            if (switchError.code === 4902) {
              console.log("Please add the Binance network to MetaMask");
            } else {
              console.log("Cannot switch to the network");
            }
          }
        
        
        
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer
          );
          setAccount(account);
          setState({ provider, signer, contract });
          document.querySelector("#us").value  = account[0];
      let alltasks = await contract.getMemos(account[0]);
      setTasks(alltasks);
      let alltasks1 = await contract.getMemos1(account[0]);
      setTasks1(alltasks1);
      const b =Number(alltasks[2]);
      const refer = (alltasks[1]);
     setrefer(refer);
     document.querySelector("#hid").value  = (alltasks[1]);
     const direct =Number(alltasks[2]);
     setDirect(direct);
     const directs = Number(alltasks[3])/1000000000000000000;
     setDirects(directs);
     const level = Number(alltasks[4])/1000000000000000000;
     setLevel(level);

     const wlevel = Number(alltasks[13])/1000000000000000000;
     setLevelw(wlevel);

     const plevel = Number(alltasks[12])/1000000000000000000;
     setLevelp(plevel);
     
    
     const pack = Number(alltasks[6])/1000000000000000000 ;
     

setpackage(pack);
const pack3 = Number(alltasks[14])/1000000000000000000 ;
     

setpackage3(pack3);


const pack1 = Number(alltasks[5]) ;
document.querySelector("#pp").value  = Number(alltasks[5]);


setpackage1(pack1);



     setB(b);

     const upd = (Number(alltasks[7]) ) /1000000000000000000  ;
          
     setupd(upd);

     const dd = Number(alltasks[9]);
          
     setDD(dd);

     const dd2 = Number(alltasks[9]);
          
     setDD2(dd2);

     const dd1 =  Date.now() / 1000;
     setDD1(dd1);


     const tt1 = Math.trunc((dd1 - dd) / (3660 * 24 )) ;
   //  const tt1 =  (dd1.getTime() - dd.getTime())/ (1000 * 60 * 60 * 24);
     
     settt1(tt1);

     const tt2 = Math.trunc((dd1 - dd2) / (3600 * 24  )) ;
     //  const tt1 =  (dd1.getTime() - dd.getTime())/ (1000 * 60 * 60 * 24);
       
       settt2(tt2);
      
       document.querySelector("#tt2").value = tt2;
       
       

       
    // const roi1 = Number(alltasks[9]) / 100000;
          
    // seroi1(roi1);

    

    // document.querySelector("#wi1").value = roi2;
     
     const a1 = Number(alltasks1[0])-1;
          
     sea1(a1);
     const a3 = Number(alltasks1[2])/1000000000000000000;
          
     sea3(a3);
     const a2 = Number(alltasks1[1])/1000000000000000000;
     sea2(a2);   
     var rr = 0;
     const tt4 =  Number(alltasks[15])/1000000000000000000;
     settt4(tt4);
     
     const tt =  level  + directs +wlevel + plevel;
     settt(tt);
     const tt3 = Number(alltasks[10])/1000000000000000000;
     settt3(tt3);
     document.querySelector("#bal").value = tt3;
     const referlink = 'https://nxttoken.com/?ref='+ account;
     setreflink(referlink);
     document.querySelector("#link").value =referlink;

console.log(pack);
     
        } else {
          alert("Please install metamask");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
   
    
    connectWallet();
    
    function myFunction() {
      
      var copyText = "hello";
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
      
    
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.valueOf);
    }

  }, []);

 
  // console.log(state);
  return (
  <div>
    <input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>
    <input type="hidden" id ="tt2"></input>
    <input type="hidden" id ="wi1"></input>
    <input type="hidden" id ="roi"></input>
    <input type="hidden" id ="bal"></input>
<div class="se-pre-con"></div>
  
<table width="100%">
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"94%", textAlign: "center",backgroundColor:"#191980 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  
  <table width="100%">
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
<table width="100%">
<tr><td><br></br></td></tr>
<tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
1 NXT <br></br> {a3} USDT
</td></tr><tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
MY TOKENS <br></br>  {pack} NXT
</td></tr><tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
MY FREEZE TOKENS <br></br>  {pack3} NXT
</td></tr><tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
MY PURCHASE <br></br> {pack1} USDT
</td></tr><tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
MY DIRECT TEAM <br></br>{direct}
</td></tr><tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 MY DIRECT INCOME <br></br> {directs} USDT
    
</td></tr><tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 MY LEVEL INCOME <br></br>{level} USDT
   
</td></tr><tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 LEADERSHIP INCOME <br></br>{wlevel} USDT
   
</td></tr><tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 PERFORMANCE INCOME <br></br>{plevel} USDT
   
</td></tr>
<tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 CURRENT WEEK BONUS  <br></br>{tt4} USDT 
   
</td></tr>
</table>  



</td> 
<td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 
<table width="100%">

<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
<img src ="logo.png" width="250px" height="250px"></img>

</td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}> BUY TOKENS<br></br><br></br>
  <input type ="text" id ="test" width={"200px"} ></input>
                            <br></br><br></br>
                            <Buy state={state}  />

</td></tr><tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
  SELL TOKENS <br></br><br></br>
  <input type ="text" id ="test1" width={"200px"} ></input>
                            <br></br><br></br>
                            <Buy3 state={state}  /> 

</td></tr>
<tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 TOTAL WITHDRAW <br></br>{upd} USDT
 
</td></tr><tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
TOTAL COIN WITHDRAW<br></br> {tt3} NXT
</td></tr>
<tr><td><br></br></td></tr>
<tr>  
    
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
 TOTAL BONUS  <br></br>{tt} USDT 
   
</td></tr>
<tr><td><br></br></td></tr>


</table>
</td> 
<td style={{width:"3%" ,}}></td>
</tr>

</table>




</td> 

<td style={{width:"3%" ,}}></td>
</tr>

<tr><td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>

  CONNECTED ACCOUNT
 <br></br>{account}
</td><td></td>
</tr>
<tr>
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
REFER BY
 <br></br>{refer}
</td><td></td>
</tr>
<tr>
<td style={{width:"3%" ,}}></td>
  <td style={{  verticalAlign:"center", width :"47%", textAlign: "center",backgroundColor:"#191970 ", border:"2px",
  borderRadius: "10px",
color: "white",
}}>
MY REFER LINK <br></br>
 {referlink} <button><img src ="copy.png" height={"25px"} onClick={() => {
         navigator.clipboard.writeText(referlink);}} /></button>
 
</td><td></td>
</tr>



</table>
<br></br>
<br></br>
</div>  
  );
}

export default App;